import React, { useContext } from 'react'
import { navigate } from 'gatsby'

import CustomQuoteLayout from '../../../views/custom-quote/components/CustomQuoteLayout'
import FormCardSelection from '../../../views/custom-quote/components/FormCardSelection'
import FormNavigation from '../../../views/custom-quote/components/FormNavigation'
import FormQuestion from '../../../views/custom-quote/components/FormQuestion'
import { CustomQuoteContext } from '../../../views/custom-quote/context/CustomQuoteManager'
import { CUSTOM_QUOTE_SEQUENCE_LINKS } from '../../../views/custom-quote/constants'

const Finishing = () => {
  const { formData, updateFormData } = useContext(CustomQuoteContext)

  const handleSelect = (item) => {
    updateFormData({
      ...formData,
      finishing: item,
    })

    navigate(CUSTOM_QUOTE_SEQUENCE_LINKS.finishing.next)
  }

  const handleSubmit = () => {
    navigate(CUSTOM_QUOTE_SEQUENCE_LINKS.finishing.next)
  }
  
  const items = [
    {
      label: 'metal plating',
    },
    {
      label: 'pantone color matching/painting',
    },
    {
      label: 'no finishing',
    }
  ]
  
  return (
    <CustomQuoteLayout>
      <FormQuestion
        title="What type of finishing do you need?"
        description="Every 3d print comes with free polishing."
      />
      <FormCardSelection
        items={items}
        onSelect={handleSelect}
        selectedValue={formData && formData.finishing}
      />
      <FormNavigation
        onSubmit={handleSubmit}
        showBackButton
        previousLink={CUSTOM_QUOTE_SEQUENCE_LINKS.finishing.previous}
      />
    </CustomQuoteLayout>
  )
}

export default Finishing